.chart-label {
    font-family: noto sans;
    font-size: 14px;
    /* font-weight: bold ; */
    letter-spacing : 0.75px;
}

.reference-line {
    stroke-width: 3;
}


.clr-black {
    color: black;
}

.rectangle-shadow {
    filter:
        drop-shadow(8px 8px 0px rgba(45, 42, 42, 0.495)) 
        /* drop-shadow(0px 8px 0px rgba(45, 42, 42, 0.438)); */
  }

@media (max-width: 768px) {
    .chart-label {
        font-size: 13px;
    }

    .reference-line {
        stroke-width: 2;
    }
}

@media (max-width: 480px) {
    .chart-label {
        font-size: 12px;
    }

    .reference-line {
        stroke-width: 1;
    }
}

