@import url('https://fonts.googleapis.com/css2?family=Charmonman&family=Noto+Sans:wght@300&display=swap');

* {
  font-family: noto sans;
}

.bodyy {
  margin: 0;
  padding: 0;
  background-color: #E5E2D3;
  height: 100vh;
  /* font-family: Arial, sans-serif; */
  display: flex;
}

.left {
  height: 80%;
  width: 60%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.question {
  text-align: justify;
  width: 100%;
  align-items: center;
  padding: 1%;
  min-height: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  color: #4e4d4d;
}

#question-number {
  margin-right: 5px;
}

.option-container {
  padding-top: 10%;
  display: row;
  margin: 15px;
  margin-top: 1.8%;

}

.buttons {
  height: 8%;
  color: white;
  margin-top: 5%;
  width: 100%;
  display: flex;
  position: relative;
  padding: 0 4%;
}

.option-btn {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  box-sizing: border-box;
  background-color: #63c62a;
  /* overflow: break-word; */
  text-align: justify;
  padding: 8px 15px;
  font-size: 1.0rem;
  outline: none;
  transition: 0.3s;
  margin: 8px;
  padding: 12px;
  min-height: 60px;
  width: 94%;
  margin-left: 25px;
  /* font-family: sans-serif; */
  font-weight: bold;
  color: rgb(56, 56, 56);
}

.checked {
  background-color: #45a110;
  color: white;
  box-shadow: 3px 3px 4px 1px rgb(78, 69, 78);
}

.option-btn:hover {
  background-color: #45a110;
  color: white;
  transform: scale(105%);
}

#next-button,
#prev-button,
.submit-button {
  padding: 10px;
  border: none;
  background-color: #4ba94b;
  color: white;
  border-radius: 5px;
  box-shadow: 1px 1px 4px 1px #333;
  font-size: larger;
  font-weight: bold;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  transition: 0.2s;
  width: 100px;
  position: absolute;
}

#next-button:hover,
#prev-button:hover {
  background-color: #38a038;
}


.submit-button:hover {
  background-color: #389738;
  color: white;
  border: #4ba94b;
}

#next-button,
.submit-button {
  margin-left: 80%;
}

#next-button:active {
  box-shadow: none;
}

.right {
  height: 80%;
  width: 40%;
  padding: 10px;
  box-sizing: border-box;
}

.right img {
  height: 35%;
  width: 32%;
  margin-top: 10%;
  margin-left: 3%;
  position: absolute;
}

.box {
  width: 20%;
  height: 7%;
  margin-top: 20%;
  margin-left: 19%;
  background-color: rgb(8, 50, 5);
  position: absolute;
}

.submit-button {
  background-color: white;
  color: rgb(8, 50, 5);
  border: solid 2px rgb(8, 50, 5);
}

/* Add these styles to your existing CSS or a separate CSS file */

.cont {
  height: 5%;
  width: 100%;
  margin-left: 0%;
  margin-top: 10%;
  display: flex;
}    
.msg,
.msg2,
.msg3,
.msg4,
.msg5{
  text-align: center;
  justify-content: center;
  font-weight: 900;
  display: flex;
}
.con{
  position: relative;
}

.msg .startImage,
.msg2 .startImage,
.msg3 .startImage,
.msg4 .startImage,
.msg5 .startImage{
height: 100px;
margin-top: 5%;
}

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    black 0%,
    black 29%,
    rgb(46, 4, 101) 67%,
    rgb(46, 4, 101)100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  display: inline-block;

}



/* PROMPT CSS */

.custom-ui {
  /* text-align: center; */
  padding: 20px;
}

.highlighted-text {
  color: #1A5D1A;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.continue-button,
.start-over-button {
  background-color: #1A5D1A;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 200px;
  /* Limit the maximum width for larger screens */
}

@media (min-width: 768px) {
  .button-container {
    flex-direction: row;
  }

  .continue-button,
  .start-over-button {
    width: auto;
    max-width: none;
  }
}

/* PROMPT CSS ENDS */
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

@media screen and (max-width: 768px) {
  .bodyy {
    flex-direction: column;
    height: 100%;
  }

  .right {
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 0%;
    padding-top: 10%;
    align-items: center;
    justify-content: center;
  }

  .right img {
    height: 200px;
    width: 300px;
    position: relative;
  }

      .box{
        background-color: #63c62a;
        height: 50%;
        width: 95%;
        margin-left: 0%;
        margin-top: 18%;
      }
      .left{
        width: 100%;
        margin-top: 55%;
        position: relative;
        padding-top: 5%;
      }
      .question{
        margin-top: 70%;
      }
      
      .buttons{
        width: 100%;
        margin-top: 2%;
        padding: 0% 15%;
        padding-bottom: 20%;
      }
      #next-button,
      .submit-button{
        margin-left: 50%;
      }
      .submit-button{
        background-color: white;
        color: #63c62a;
        border:solid 2px #63c62a;
      }
      
      .option-container{
        margin-left: 0%;
        margin-right: 5%;
      }
      .cont{
        position: absolute;
        margin-top: 80%;
      }
      .msg,
      .msg2,
      .msg3,
      .msg4,
      .msg5{
        position: absolute;
        margin-top: 115%;
      }
      .msg .startImage,
      .msg2 .startImage,
      .msg3 .startImage,
      .msg4 .startImage,
      .msg5 .startImage{
       position: absolute;
       margin-top: 22%;
      }
     
    }
    
    