.page{
  /* background-image: none; */
  background: url("../images/bg.png");
}

.instructions-page {
    max-width: 800px; /* Widen the box */
    margin: 0 auto;
    padding: 40px;
    padding-top: 0;
    text-align: center;
    /* background-color: #f5f5f5; */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .instructions-page h1 {
    font-size: 2.5rem;
    color: #333;
  }
  
  .instructions-page p {
    font-size: 1.2rem;
    line-height: 1.6;
    text-align: left;
    color: #666;
  }
  
  .instructions-page ol {
    text-align: left;
  }
  
  .instructions-page ol li {
    font-size: 1.1rem;
    margin-bottom: 8px;
  }
  
  .start-button-container {
    margin-top: 40px;
  }

  .start-button {
    display: inline-block;
    padding: 12px 24px;
    font-size: 1.2rem;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 8px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .start-button:hover {
    background-color: #0056b3;
  }
  

 

/* Privacy Policy Container */
.privacy-policy-container-inst {
    display: flex;
    /* align-items: center; */
    margin-top: 20px;
  }
  
  /* Custom Checkbox */
  .checkbox-label {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-right: 20px;
    cursor: pointer;
  }
  
  .checkbox-label input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Style the checkmark */
  .checkbox-label .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 2px solid #007bff;
    border-radius: 3px;
  }
  
  /* When the checkbox is checked, display the checkmark */
  .checkbox-label input[type="checkbox"]:checked ~ .checkmark:after {
    content: "";
    position: absolute;
    display: block;
    left: 5px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #007bff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  /* Privacy Policy Link */
  .privacy-policy-link {
    margin-top: 10px; /* Adjust the margin to control the link's vertical position */
  }
  
  .privacy-policy-link a {
    text-decoration: none;
    color: #007bff; /* Link color */
    font-weight: bold;
  }
  
  .privacy-policy-link a:hover {
    text-decoration: underline; /* Underline on hover */
  }


  @media (max-width: 768px) {
    .privacy-policy-container {
      flex-direction: column; /* Stack elements vertically on small screens */
    }
  
    /* Adjust styles for smaller screens */
    .checkbox-label {
      margin-top: 10px; /* Adjust spacing for the checkbox label */
    }
  }