/* PrivacyPolicy.css */

/* Container */
.privacy-policy-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Header */
.privacy-policy-header {
  text-align: center;
  margin-bottom: 20px;
}

.privacy-policy-header h1 {
  font-size: 36px;
  color: #333;
}

/* Section */
.privacy-policy-section {
  margin-top: 20px;
}

.privacy-policy-section ol {
  list-style-type: decimal;
  padding-left: 20px;
  color: #555;
}

.privacy-policy-section ol li {
  margin-bottom: 10px;
}

/* .privacy-policy-section strong {
  font-weight: bold;
} */

/* Footer */
.privacy-policy-footer {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
  color: #777;
}

.privacy-policy-footer a {
  color: #007bff;
  text-decoration: none;
}

.privacy-policy-footer a:hover {
  text-decoration: underline;
}
