@import url("https://fonts.googleapis.com/css2?family=Charmonman&family=Noto+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

.u-about-page {
    max-width: 100%;
    min-height: 60vh;
    /* padding: 1.2rem 3rem; */
}

.u-about-page-section-1-bg {
    background-image: url("../images/aboutBgmobile.png");
    background-position: center;
    background-size: cover;
}

h2 {
    font-family: serif;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 0px;
    margin-bottom: 1rem;
    text-align: center;
}

p {
    font-family: "Montserrat", sans-serif;
    font-size: 1.1rem;
    color: black;
    font-weight: 500;
}

.horizontal-line {
    width: 100%;
    margin-top: 0.6rem;
    margin-bottom: 2rem;
    height: 1px;
    background: rgb(117, 117, 117);
    background: linear-gradient(
        90deg,
        rgba(117, 117, 117, 0.196516106442577) 0%,
        rgba(117, 117, 117, 1) 30%,
        rgba(117, 117, 117, 1) 72%,
        rgba(117, 117, 117, 0.20211834733893552) 100%
    );
}

.circle {
    background-color: #294826;
    border-radius: 200%;
    height: 20px;
    width: 20px;
}

/* --------------------- SECTION 1 --------------------- */

.u-about-page-section-1 {
    backdrop-filter: blur(8px) contrast(20%) brightness(150%);
    -webkit-backdrop-filter: blur(10px);
}

.u-about-page-section-1-heading {
    padding: 2rem 3rem;
    color: #294826;
    display: flex;
    justify-content: space-between;
}

.u-about-page-section-1-heading-img {
    width: 200px;
    margin-bottom: 1rem;
    position: relative;
}

.u-about-page-section-1-heading img {
    width: 100%;
    position: absolute;
    top: -30px;
}

.u-about-page-section-1-heading h1 {
    font-family: serif;
    font-weight: bold;
    font-size: 3rem;
    margin-bottom: 0px;
}

.u-about-page-section-1-heading span {
    font-family: serif;
    font-style: italic;
    font-size: 2.2rem;
}

.u-about-page-section-1-heading-p {
    padding: 0rem 3rem 3rem 3rem;
}

/* --------------------- SECTION 2 --------------------- */

.u-about-page-section-2 {
    background-color: #ffffff;
    padding: 3rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.u-about-page-section-2 li {
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.u-about-page-section-2 li:last-child {
    margin-bottom: 0px;
}

/* --------------------- SECTION 3 --------------------- */

.u-about-page-section-3 {
    background-color: #bbcbca;
    padding: 3rem 3rem 0rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.u-about-page-section-3__boxes {
    margin-top: 1.6rem;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 1rem 0rem;
}

.u-about-page-section-3__box {
    background-color: #e5e2d3;
    padding: 1.6rem;
    max-width: 400px;
    font-family: "Montserrat", sans-serif;
    font-size: 1.1rem;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    font-weight: 500;
    margin: 1rem 0rem;
}

/* --------------------- SECTION 4 --------------------- */

.u-about-page-section-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 2rem;
}

.u-about-page-section-4 div:first-child {
    padding: 3rem 3rem;
}

.u-about-page-section-4__img {
}

.u-about-page-section-4 img {
    width: 80%;
    /* position: absolute; */
}

/* --------------------- SECTION 5 --------------------- */

.u-about-page-section-5 {
    padding: 3rem;
    background-color: #bbcbca;
    display: flex;
    justify-content: center;
}

.u-about-page-section-5 img {
    width: 80%;
}

/* --------------------- SECTION 6 --------------------- */

.u-about-page-section-6 {
    display: flex;
    gap: 3rem;
    padding: 2rem;
}

.u-about-page-section-6__sub {
    width: 50%;
    background-color: #e5e2d3;
    padding: 2rem;
}

.u-about-page-section-6__sub-flowchart {
    margin: 0rem;
    display: flex;
    justify-content: center;
}

.u-about-page-section-6__sub-flowchart img {
    margin: 2rem 0rem;
    width: 80%;
}

.u-about-page-section-6__boxes {
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin: 2rem 0;
}

.u-about-page-section-6__box {
    background-color: #294826;
    padding: 1.4rem;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    color: #ffffff;
    font-weight: 500;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px 0px;
}

.u-about-page-section-6__metrics {
    margin-top: 3rem;
}

.u-about-page-section-6__metrics-box {
    display: flex;
    justify-content: space-evenly;
    margin-top: 2.4rem;
}

.u-about-page-section-6__metrics-box div {
    display: flex;
    flex-basis: 33%;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
}

.u-about-page-section-6__metrics-box img {
    width: 90px;
    aspect-ratio: 3/2;
    object-fit: contain;
}

.u-about-page-section-6__metrics-box span {
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
}

.u-about-page-section-6__metrics h3 {
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-align: center;
}

.u-about-page-section-7 {
    display: flex;
    padding: 2rem;
    flex-direction: column;
    align-items: center;
}

.u-about-page-section-7__metrics-box {
    display: flex;
    justify-content: space-evenly;
    margin-top: 2.4rem;
    gap: 4rem;
}

.u-about-page-section-7__metrics-box div {
    display: flex;
    flex-basis: 33%;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
}

.u-about-page-section-7__metrics-box img {
    width: 150px;
    aspect-ratio: 3/2;
    object-fit: contain;
}

.u-about-page-section-7__metrics-box span {
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
}

.u-about-page-section-8 {
    padding: 2rem 3rem;
}

.u-about-page-section-8 {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.u-about-page-section-8__heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: max-content;
    margin: 0rem auto;
}

.u-about-page-section-8__heading .horizontal-line {
    margin-top: -1rem;
}

.u-about-page-section-8__head {
    position: relative;
    display: flex;
    /* justify-content: center; */
    gap: 1rem;
    align-items: center;
}

.u-about-page-section-8__head img {
    width: 100px;
    padding-bottom: 30px;
}

@media only screen and (max-width: 1024px) {
    .u-about-page-section-3__box {
        margin: 0.8rem 0rem;
    }
    .u-about-page-section-5 img {
        width: 100%;
    }

    .u-about-page-section-6__boxes {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    .u-about-page-section-6__metrics-box {
        flex-wrap: wrap;
    }

    .u-about-page-section-6__metrics-box div {
        margin: 1rem 1rem;
    }

    .u-about-page-section-6__sub-flowchart img {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    p {
        font-size: 1rem;
    }
    .u-about-page-section-5 {
        padding: 2rem;
        background-color: #bbcbca;
        display: flex;
        justify-content: center;
    }

    .u-about-page-section-6__metrics-box {
        flex-direction: column;
    }

    .u-about-page-section-3__box {
        font-size: 1rem;
    }

    .u-about-page-section-6__box {
        font-size: 1rem;
    }

    .u-about-page-section-2 li {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 640px) {
    h1 {
        text-align: start;
    }

    h2 {
        font-size: 1.5rem;
    }

    .u-about-page-section-1-heading-img {
        width: 200px;
        margin-bottom: 1rem;
        position: relative;
    }

    .u-about-page-section-1-heading img {
        width: 90%;
        position: absolute;
        top: -20px;
        right: -20px;
    }

    .u-about-page-section-1-heading h1 {
        font-size: 2.4rem;
    }

    .u-about-page-section-1-heading span {
        font-size: 1.4rem;
    }

    .u-about-page-section-6 {
        flex-direction: column;
    }

    .u-about-page-section-6__sub {
        width: 100%;
    }

    .u-about-page-section-7__metrics-box {
        justify-content: space-evenly;
        flex-direction: column;
    }
}
